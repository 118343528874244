import axios from 'axios';
let base = window.location.origin;
const api_base = base && base.includes('localhost') ? 'http://localhost:8848' : 'https://weavecontent.io';
const getUrlParams = (search) => {
    const hashes = search.slice(search.indexOf('?') + 1).split('&');
    const params = {};
    hashes.map(hash => {
        const [key, val] = hash.split('=');
        params[key] = decodeURIComponent(val);
    });
    return params;
}



document.addEventListener("DOMContentLoaded", function(){
    const fetch = axios.create({
        headers:{
            'X-Request-WeaveContent-Install': window['w-content'] ? window['w-content'].guid : null,
        }
    });
    console.log('window.location',window.location);
    const location = window.location;
    console.log('location',location);
    const search = getUrlParams(window.location.search);
    console.log('search',search);
    
    const areas = document.querySelectorAll('.wc-area');
    if(areas && areas.length){
        areas.forEach(area => {
            console.log('area',area);
            const areaGUID= area.getAttribute('data-wc-area-guid');
            const params = {areaGUID,location:window.location.href};
            console.log('params',params);
            fetch.get(api_base  + '/api/render',{params:{...search,...params}}).then(function({data}) {
                console.log(data);
            });

            //fetch.get(api_base  + '/api/render',{params}).then(function({data}) {
            //     area.innerHTML = data.html;
            //     if(data.click){
            //         area.addEventListener('click',function(){
            //             console.log('we got a click');
            //         });
            //     }
            //     area.style['visibility'] = 'visible';
            // });
        });
    }
});
